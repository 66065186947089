import React from 'react'
import { ProgressSpinner } from 'primereact/progressspinner';
        

function Index(props) {

    return (
        <div style={{ display: 'flex', height: props.height ? props.height : "100vh", width: "100%", alignItems: "center", justifyContent: "center" }}>
            <ProgressSpinner style={{ width: '50px', height: '50px' }} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />
        </div>
    )
}

export default Index