import React from 'react'
import { PriceFormat } from '../../../helper';
import { Link, useNavigate } from 'react-router-dom';
import { Avatar } from 'primereact/avatar';
import Style from "./genralCourseCard.module.scss";
import Rsp from "../../../assets/images/rsp.svg";

function Index(props) {
    let navigate = useNavigate();
    const { data, categoryName } = props;

    const buyNowClick=()=>{
        navigate(`/course/${categoryName}/${data.seo_title}?buy=true`);
    }

   const onCilckHandler = ()=>{
    navigate(`/course/${categoryName}/${data.seo_title}`);
    }

    return (
        // <Link to={`/course/${categoryName}/${data.seo_title}`} className='no-underline text-900'>
            <div className='shadow-2 border-round' onClick={()=> onCilckHandler()}>
                <img loading="lazy" src={data.images} alt={data.seo_title} className='w-full border-round-top' />
                <div className='p-3 pt-2 flex flex-column gap-2 justify-content-between' style={{ minHeight: '9rem' }}>
                    <div className='relative'>
                        <div className='flex gap-2 align-items-center'><p className='w-2rem bg-primary-900' style={{ height: 5 }}></p> <span className='font-semibold'>{PriceFormat(data.new_price)}</span></div>
                        <div className='absolute  right-0' style={{ top: -30 }}>
                            <Avatar image={Rsp} size="large" shape="circle" className='border-circle' />
                            {/* <img sloading="lazy" src={data.images} alt={data.seo_title} className='border-circle w-2rem h-2rem'/> */}
                        </div>
                    </div>
                    <div className=''>
                        <p className='text-xs mb-2'>Mr. Rajendra Suryawanshi</p>
                        <p className={[Style.desc, 'font-semibold'].join(" ")} style={{ height: '2.6rem' }}>{data.title}</p>
                    </div>
                    <div className='flex gap-2 text-sm justify-content-between align-items-center'>
                        <div className='flex gap-4 font-medium text-700'>
                            <div className='flex gap-1 align-items-center'>
                                <i className='pi pi-user text-primary-900 text-xs '></i>
                                <p>Student: {data.student_count}</p>
                            </div>
                            <div className='flex gap-1 align-items-center'>
                                <i className='pi pi-book text-primary-900 text-xs '></i>
                                <p>Review: <span >{data.rating_count}</span></p>
                            </div>
                        </div>
                        <div onClick={(event)=> event.stopPropagation()}>
                            {/* <Link to={`/course/${categoryName}/${data.seo_title}`} > */}
                            <p onClick={()=> buyNowClick()} className='white-space-nowrap font-bold border-round px-3 py-1 border-primary-900 text-primary-900 hover:border-1 cursor-pointer '>Buy now</p>
                            {/* </Link> */}
                        </div>
                    </div>
                </div>
            </div>
        // </Link>
    )
}

export default Index


