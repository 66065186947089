import React, { useRef, useState } from 'react';
import Logo from '../../assets/images/logo.svg'
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { Button } from 'primereact/button';
import { Sidebar } from 'primereact/sidebar';
import { Avatar } from 'primereact/avatar';
import { Menu } from 'primereact/menu';
import { api, axios } from '../../services';
import { deleteHeader } from '../../helper/requestHeaders';
import { deleteLogin } from '../../slice/loginSlice';
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2';




function Index() {
    const menuLeft = useRef(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [visible, setVisible] = useState(false);
    const loginObject = localStorage.getItem("login")
        ? JSON.parse(localStorage.getItem("login"))
        : {};

    let items = [];

    if (true) {
        items = [
            {
                label: 'Menu',
                items: [
                    {
                        label: 'My learning',
                        icon: 'pi pi-book',
                        command: (e) => {
                            navigate("/learning", {
                                replace: true,
                            });
                        }
                    },
                    {
                        label: 'My questions',
                        icon: 'pi pi-question-circle',
                        command: (e) => {
                            navigate("/question", {
                                replace: true,
                            });
                        }
                    },
                    // {
                    //     label: 'Notification',
                    //     icon: 'pi pi-bell',
                    //     command: (e) => {
                    //         navigate("/notification", {
                    //             replace: true,
                    //         });
                    //     }
                    // }
                ]
            },
            { separator: true },
            {
                label: 'Profile',
                items: [
                    {
                        label: 'My account',
                        icon: 'pi pi-user',
                        command: (e) => {
                            navigate("/profile", {
                                replace: true,
                            });
                        }
                    },
                    {
                        label: 'Logout',
                        icon: 'pi pi-sign-out',
                        command: (e) => {
                            onClickLogout();
                        }
                    }
                ]
            }
        ];
    }

    // logout
    const onClickLogout = async () => {
        try {
            const result = await axios.delete(api.USER_LOGOUT);
            if (result) {
                const deleted = localStorage.removeItem("login");
                const delHeader = deleteHeader();
                const delLoginSlice = dispatch(deleteLogin());
                if (deleted === undefined && delHeader && delLoginSlice) {
                    if (result.data.status !== 200) {
                        Swal.fire({
                            icon: "error",
                            title: "Oops",
                            text: result.data.message,
                        });
                    } else if (result.data.status === 200) {
                        navigate("/");
                        Swal.fire({
                            icon: "success",
                            title: "Congratulations",
                            text: "you have successfully logged out.",
                        });
                    }
                }
            }
        } catch {
            Swal.fire({
                icon: "error",
                title: "Oops",
                text: "invalid token try again",
            });
        }
    };

    return (
        <div className='flex align-items-center justify-content-center px-2 md:px-0 h-4rem lg:h-8rem'>
            <div className='flex align-items-center justify-content-between w-full ' style={{ maxWidth: '1144px' }}>
                <div className='flex gap-1 align-items-center'>
                    <div className='lg:hidden cursor-pointer hover:bg-gray-400 p-2 border-circle' onClick={() => setVisible(true)} >
                        <i className="pi pi-bars" ></i>
                    </div>
                    <div className='p-1 px-2 border-round' >
                        <NavLink to="/"><img src={Logo} alt="logo" className='w-6rem md:w-14rem' /></NavLink>
                    </div>
                </div>
                <div className='gap-4 align-items-center hidden lg:flex'>
                    <NavLink to="/" className={({ isActive }) => (isActive ? 'border-bottom-2  border-primary-900  no-underline  text-900 font-medium text-primary-900' : 'border-bottom-2 border-transparent  no-underline text-900 font-medium hover:text-primary-900')}>
                        <p>
                            Home
                        </p>
                    </NavLink>
                    <NavLink to="/course" className={({ isActive }) => (isActive ? 'border-bottom-2 border-primary-900  no-underline text-900 font-medium text-primary-900' : 'border-bottom-2 border-transparent  no-underline text-900 font-medium hover:text-primary-900')}>
                        <p>
                            Course
                        </p>
                    </NavLink>
                    <NavLink to="/sebi" className={({ isActive }) => (isActive ? 'border-bottom-2 border-primary-900  no-underline text-900 font-medium text-primary-900' : 'border-bottom-2 border-transparent  no-underline text-900 font-medium hover:text-primary-900')}>
                        <p>
                            SEBI
                        </p>
                    </NavLink>
                    {/* <NavLink to="/blog" className={({ isActive }) => (isActive ? 'border-bottom-2 border-primary-900  no-underline text-900 font-medium text-primary-900' : 'border-bottom-2 border-transparent  no-underline text-900 font-medium hover:text-primary-900')}>
                        <p>
                            Blog
                        </p>
                    </NavLink> */}
                    <NavLink to="/about" className={({ isActive }) => (isActive ? 'border-bottom-2 border-primary-900  no-underline text-900 font-medium text-primary-900' : 'border-bottom-2 border-transparent  no-underline text-900 font-medium hover:text-primary-900')}>
                        <p>
                            About
                        </p>
                    </NavLink>
                    <NavLink to="/contact" className={({ isActive }) => (isActive ? 'border-bottom-2 border-primary-900  no-underline text-900 font-medium text-primary-900' : 'border-bottom-2 border-transparent  no-underline text-900 font-medium hover:text-primary-900')}>
                        <p>
                            Contact
                        </p>
                    </NavLink>
                </div>
                {Object.keys(loginObject).length > 0 ?
                    // <Link to="/myaccount" className='no-underline text-900'>
                    <div>
                        <Menu model={items} popup ref={menuLeft} id="popup_menu_left" />
                        <div className='flex gap-2 align-items-center cursor-pointer px-2 py-1 border-round' onClick={(event) => menuLeft.current.toggle(event)} aria-controls="popup_menu_left" aria-haspopup>
                            <Avatar image={loginObject['profile-pic']} className='border-circle' shape="circle" />
                            <p className='font-medium capitalize'>{loginObject?.name || loginObject.email.split('@')[0]}</p>
                        </div>
                    </div>
                    // </Link>
                    :
                    <div className='flex gap-2'>
                        <NavLink to="/login" className='hidden lg:block'><Button label="Login" text size='small' /></NavLink>
                        <NavLink to='/sign-up' ><Button label="Create account" raised size='small' /></NavLink>
                    </div>}
            </div>
            {/* sidebar */}
            <Sidebar visible={visible} onHide={() => setVisible(false)}>
                <div className='flex flex-column justify-content-between h-full'>
                    <div>
                        <p className='text-500 font-blod mb-2'>Menu</p>
                        <div className='gap-1 align-items-start flex flex-column lg:hidden'>
                            <NavLink to="/" onClick={() => setVisible(false)} className="text-900 p-3 border-round no-underline hover:surface-ground w-full">
                                <p>
                                    Home
                                </p>
                            </NavLink>
                            <NavLink to="/course" onClick={() => setVisible(false)} className="text-900  p-3 border-round no-underline hover:surface-ground w-full">
                                <p>
                                    Course
                                </p>
                            </NavLink>
                            <NavLink to="/sebi" onClick={() => setVisible(false)} className="text-900  p-3 border-round no-underline hover:surface-ground w-full">
                                <p>
                                    SEBI
                                </p>
                            </NavLink>
                            {/* <NavLink to="/blog" onClick={() => setVisible(false)} className="text-900  p-3 border-round no-underline hover:surface-ground w-full">
                                <p>
                                    Blog
                                </p>
                            </NavLink> */}
                            <NavLink to="/about" onClick={() => setVisible(false)} className="text-900  p-3 border-round no-underline hover:surface-ground w-full">
                                <p>
                                    About
                                </p>
                            </NavLink>
                            <NavLink to="/contact" onClick={() => setVisible(false)} className="text-900  p-3 border-round no-underline hover:surface-ground w-full">
                                <p>
                                    Contact
                                </p>
                            </NavLink>
                        </div>
                    </div>

                    {Object.keys(loginObject).length > 0 ?
                        <Link to="/myaccount" className='hidden no-underline text-900' onClick={() => setVisible(false)}>
                            <div className='flex gap-2 align-items-center cursor-pointer px-2 py-1 border-round'>
                                <Avatar image={loginObject['profile-pic']} className='border-circle' shape="circle" />
                                <p className='font-medium capitalize'>{loginObject?.name || loginObject.email.split('@')[0]}</p>
                            </div>
                        </Link>
                        :
                        <div className="">
                            <NavLink to='/login' onClick={() => setVisible(false)} ><Button label="Login" raised className='w-full' /></NavLink>
                        </div>
                    }


                </div>
            </Sidebar>
        </div>
    );
}

export default Index
