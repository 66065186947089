const BASE_SERVER_URL = process.env.REACT_APP_BASE_SERVER_URL;
const BASE_SERVER_VERSION = process.env.REACT_APP_BASE_SERVER_VERSION;
// const BASE_URL = `/${BASE_SERVER_VERSION}`;
const BASE_URL = `${BASE_SERVER_VERSION}`;
const BASE_AUTH_URL = `/${process.env.REACT_APP_BASE_AUTH_URL}`;

const apis = {
  // Home
  HOME: `${BASE_SERVER_URL}${BASE_URL}/home`,
  AUTH_COURSE: `${BASE_SERVER_URL}${BASE_URL}/auth_course`,

  // courses apis
  COURSES: `${BASE_SERVER_URL}${BASE_URL}/courses`, //get
  COURSEDETAIL: `${BASE_SERVER_URL}${BASE_URL}/course/`, //get
  LEARNAPI: `${BASE_SERVER_URL}${BASE_URL}/learn/`, //get
  BUYCOURSE: `${BASE_SERVER_URL}/courses/v0/get_order_id/`, //get
  BUYCOURSEAPI: `${BASE_SERVER_URL}/courses/v0/finalize-course/`, //get ""
  CONTACT_US: `${BASE_SERVER_URL}${BASE_URL}/contact_us`, //post
  TELEGRAM_API: `${BASE_SERVER_URL}api/v0/setup_course_telegram/`, //GET
  TELEGRAM_VERIFY_API: `${BASE_SERVER_URL}${BASE_URL}/api/v0/verify_course_telegram/`, //GET
  STOCK_API: `${BASE_SERVER_URL}/api/v0/get_stock_api`, //GET

  VERIFY_FACEBOOK_ACCESS_TOKEN: `${BASE_SERVER_URL}/auth/v0/facebook-connect/`, //POST
  FACEBOOK_LOGIN_ACCESS_TOKEN: `${BASE_SERVER_URL}/auth/v0/facebook-login/`, //POST
  VERIFY_GOOGLE_ACCESS_TOKEN: `${BASE_SERVER_URL}/auth/v0/google-connect/`, //POST
  GOOGLE_LOGIN_ACCESS_TOKEN: `${BASE_SERVER_URL}/auth/v0/google-login/`, //POST
  SUBSCRIBE: `${BASE_SERVER_URL}${BASE_URL}/subscribe-newsletter/`, //POST

  GET_PUB_QUE: `${BASE_SERVER_URL}/que-ans/v0/get-pub-question`, //GET
  GET_PUB_QUE_CAT: `${BASE_SERVER_URL}/que-ans/v0/get-cat-question/`, //GET

  USER_REGISTER: `${BASE_SERVER_URL}/auth/v0/register/`, //post
  USER_REGISTER_OTP_PASSWORD: `${BASE_SERVER_URL}/auth/v0/register`, //put
  USER_LOG_IN_WITH_PASSWORD: `${BASE_SERVER_URL}/auth/v0/login_with_password/`, //post
  USER_LOG_IN_WITH_GOOGLE: `${BASE_SERVER_URL}/auth/v0/google-login/`,
  USER_LOG_IN_WITH_FACEBOOK: `${BASE_SERVER_URL}/auth/v0/facebook-login/`,
  USER_FORGET_PASS_SEND_OTP: `${BASE_SERVER_URL}/auth/v0/forgot_password/`, //post
  USER_FORGET_PASS_VERIFY_OTP: `${BASE_SERVER_URL}/auth/v0/forgot_password`, //put
  USER_GET_PROFILE: `${BASE_SERVER_URL}/profile/v0/get-me/`, //get
  USER_UPDATE_PROFILE: `${BASE_SERVER_URL}/profile/v0/update-me/`, //put
  USER_SUBSCRIBED_COURCES: `${BASE_SERVER_URL}/courses/v0/get-my-courses`, //put

  USER_MY_COURCES: `${BASE_SERVER_URL}/api/v0/my-courses`, //get
  USER_POST: `${BASE_SERVER_URL}/que-ans/v0/get-question`, //GET
  USER_ASK_QUESTION: `${BASE_SERVER_URL}/que-ans/v0/raise-question`,
  USER_LOGOUT: `${BASE_SERVER_URL}/auth/v0/logout/`, //put
  USER_REPLY_POST_RESPONSE: `${BASE_SERVER_URL}/que-ans/v0/save-response`, //post
  USER_ASK_QUESTION_IN_MASTER: `${BASE_SERVER_URL}/que-ans/v0/raise-question`, //post
  USER_ALL_ASKED_QUESTIONS: `${BASE_SERVER_URL}/que-ans/v0/get-question`, //get
  USER_MY_ASKED_QUESTIONS: `${BASE_SERVER_URL}/que-ans/v0/get-my-question`, //get
  //admin apis
  ADMIN_OPEN_QUERY: `${BASE_SERVER_URL}/que-ans/v0/get-pub-question`,
  ADMIN_QUESTION_RESPONSE: `${BASE_SERVER_URL}/que-ans/v0/save-response`,
  ADMIN_UPDATE_QUESTION_RESPONSE: `${BASE_SERVER_URL}/que-ans/v0/update-response`,
  ADMIN_ADVANCE_TRADING_QUESTION: `${BASE_SERVER_URL}/que-ans/v0/get-cat-question/Advance Trading`,
  ADMIN_NIFTY_TRADING_QUESTION: `${BASE_SERVER_URL}/que-ans/v0/get-cat-question/Nifty Trading`,
  POST_DETAILS: `${BASE_SERVER_URL}/que-ans/v0/get-question-detail`, //GET
  ADMIN_UPDATE_QUESTION: `${BASE_SERVER_URL}/que-ans/v0/update-topic`, //PUT
};

export default apis;
