import React, { Suspense, lazy, useEffect } from "react";
import { Header, Loading, PageNotFound, Footer } from "./components";
import { Routes, Route, useLocation } from "react-router-dom";
import UserPrivateRoute from "./auth/privateRoute/userPrivateRoute";

//? ###### Lazy Loadings #######
const Login = lazy(() => import("./auth/login"));
const ForgotPassword = lazy(() => import("./auth/forgotPassword"));
const SignUp = lazy(() => import("./auth/signup"));


const Home = lazy(() => import("./pages/home"));
const Course = lazy(() => import("./pages/courses"));
const CourseDetails = lazy(() => import("./pages/courseDetails"));
const MasterCourseDetails = lazy(() => import("./pages/masterCourseDetails"));

const TermsAndConditions = lazy(() => import("./pages/termsAndConditions"));
const PrivacyPolicy = lazy(() => import("./pages/privacyPolicy"));




const Contact = lazy(() => import("./pages/contact"));
const About = lazy(() => import("./pages/about"));
const Sebi = lazy(() => import("./pages/sebi"));



// user dashboard
const UserDashboard = lazy(() => import("./userDashboard"));
const Profile = lazy(() => import("./userDashboard/profile"));
const Notification = lazy(() => import("./userDashboard/notification"));
const Learning = lazy(() => import("./userDashboard/learning"));
const Question = lazy(() => import("./userDashboard/question"));
const VideoWatch = lazy(() => import("./userDashboard/videoWatch"));







function App() {
  const urls = useLocation();



  useEffect(() => {
    window.scroll(0, 0);
  }, [urls.pathname]);


  return (
    <div className=" min-h-screen overflow-x-hidden ">
      <Header />
      <Suspense fallback={<Loading />}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/course" element={<Course />} />
          <Route path="/course/:category/:course" element={<CourseDetails />} />
          <Route path="/m/:category/:course" element={<MasterCourseDetails />} />


          {/* <Route path="/course/:courseId" element={<CourseDetails />} /> */}

          <Route path="/contact" element={<Contact />} />
          <Route path="/sebi" element={<Sebi />} />
          <Route path="/about" element={<About />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/sign-up" element={<SignUp />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-and-conditions" element={<TermsAndConditions />} />



          {/* user dashboard  */}

          <Route element={<UserPrivateRoute />}>
            <Route
              path="/learn/:category/:id/:content_id"
              element={<VideoWatch />}
            />
            <Route path="/" element={<UserDashboard />}>
              <Route path="profile" element={<Profile />} />
              <Route path="learning" element={<Learning />} />
              <Route path="question" element={<Question />} />
              <Route path="notification" element={<Notification />} />

            </Route>
          </Route>

          {/* user dashboard end */}


          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </Suspense>
      <Footer />
    </div>
  );
}

export default App;
