import React, { useEffect, useState } from 'react';
import Logo from "../../assets/images/logo.svg"
import { Link } from 'react-router-dom';
import UpstoxImg from "../../assets/images/Upstox-logo.webp";
import { Button } from 'primereact/button';
import { api, axios } from '../../services';


function Index() {
    const courseListInitial = {
        isLoading: true,
        error: false,
        courseList: [],
    };

    const [courseLists, setCourseLists] = useState(courseListInitial);

    useEffect(() => {
        fetchCourse();
    }, []);

    const fetchCourse = async (req, res) => {
        setCourseLists({ ...courseLists, isLoading: false });
        try {
            const result = await axios.get(api.HOME);
            if (result.data?.category) {
                result.data?.category.map((c) => {
                    if (!c.is_master) {
                        setCourseLists({
                            ...courseLists,
                            courseList: c.category,
                            isLoading: false,
                        });
                    }
                });
            }
        } catch (error) {
            setCourseLists({ ...courseLists, error: true, isLoading: false });
        }
    };
    return (
        <>
            <div className='bg-primary-900 px-2 md:px-0  text-white flex align-items-center justify-content-center'>
                <div className='py-4 w-full' style={{ maxWidth: '1144px' }}>
                    <div className='flex gap-2 align-items-center justify-content-between mb-3 flex-wrap'>
                        <div className='p-2 px-4   border-round bg-white' >
                            <Link to="/" ><img src={Logo} alt="logo" className=' w-14rem' /></Link>
                        </div>
                        {/* <div>
                        <img src={Logo} alt="logo" className='w-10rem' />
                    </div> */}
                        <div className='flex align-items-center gap-4'>
                            <p>Follow Us On Social Media</p>
                            <div>
                                <div className='flex align-items-center justify-content-center gap-2'>
                                    <a href='https://www.facebook.com/rajendra.suryawanshi.79' className='text-white no-underline bg-primary-800 bg-secondary-900 border-round p-2'><i className='text-2xl pi pi-facebook'></i></a>
                                    <a href="https://www.instagram.com/rajendra.surywanshi/?igshid=zt5920ux7yn4" className='text-white no-underline bg-primary-800 border-round p-2'><i className='text-2xl pi pi-instagram'></i></a>
                                    <a href="https://www.youtube.com/c/RajendraSuryawanshiStockMarketAcademy" className='text-white no-underline bg-primary-800 border-round p-2'><i className='text-2xl pi pi-youtube'></i></a>
                                    <a href="https://t.me/rvsoption" className='text-white no-underline bg-primary-800 border-round p-2'><i className='text-2xl pi pi-telegram'></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='border-top-1 border-primary-800 mt-2 py-4 grid text-align-center'>
                        <div className='col-12  lg:col-4'>
                            <p className='font-bold pb-3'>Important Links</p>
                            <div className='flex flex-column gap-2'>
                                <Link to='/' className='text-white no-underline font-medium'>Home</Link>
                                <Link to='/sebi' className='text-white no-underline font-medium'>SEBI</Link>
                                <Link to='/course' className='text-white no-underline font-medium'>Course</Link>
                                <Link to='/about' className='text-white no-underline font-medium'>About us</Link>
                                <Link to='/contact' className='text-white no-underline font-medium'>Contact us</Link>
                            </div>
                        </div>
                        <div className='col-12  lg:col-4'>
                            <p className='font-bold pb-3'>Course Links</p>
                            <div className='flex flex-column gap-2'>

                                {courseLists.error ? (
                                    <>Oh no, there was an error</>
                                ) : courseLists.isLoading ? (
                                    <>Loading...</>
                                ) : courseLists?.courseList?.length > 0 ? (
                                    courseLists.courseList.slice(0, 3).map((list, index) => (
                                        <Link to={`/course/Intraday Trading/${list.seo_title}`} key={index} className='text-white no-underline font-medium'>{list.title}</Link>
                                    ))
                                ) : null}
                                 <Link to='privacy-policy' className='text-white no-underline font-medium'>Privacy & Policy</Link>
                            <Link to='terms-and-conditions' className='text-white no-underline font-medium'>Terms of use</Link>
                            </div>
                        </div>
                        <div className='col-12  lg:col-4'>
                            <p className='font-bold mb-2'>Open an account with</p>
                            {/* <div>
                                <p className='mb-1'>We dont send span so dont worry</p>
                                <div className={["shadow-2 mt-2 border-round relative flex justify-content-center w-full"].join(" ")} >
                                    <InputText value={value} onChange={(e) => setValue(e.target.value)} className='w-full' placeholder='Enter email..' />
                                    <div className='flex justify-content-center h-full border-round-right-md cursor-pointer absolute top-0 right-0 align-items-center p-2 px-3 bg-primary-800 text-white'>
                                        <i className='pi pi-send' style={{ transform: 'rotate(45deg)' }}></i>
                                    </div>
                                </div>
                            </div> */}
                            <img src={UpstoxImg} alt="upstox pic" className='w-full  border-round' style={{ maxHeight: '3rem' }} />
                            <a href='https://upstox.com/open-demat-account/?f=EV' target='blank'><Button label="Click here to open account" /></a>
                        </div>
                    </div>
                    {/* <div className='flex flex-wrap gap-2 mt-3 align-items-center justify-content-between'>
                        <div className='flex gap-3 flex-wrap'>
                           ghhj
                        </div>
                    </div> */}

                </div>
            </div>
            <div className=' bg-primary-800 flex align-items-center justify-content-center'>
            <div className='flex align-items-center w-full justify-content-between font-semibold text-white' style={{maxWidth:'1144px'}}>
            <p className='text-sm'>&copy; {new Date().getFullYear()} all rights reserved, Rajendra Suryawanshi Stock Market Academy</p>
                
                <a href="https://neutrolink.in/">
                    <div className='my-2 text-sm flex align-items-center gap-2'>
                        <p className='text-white'>Developer By </p>
                        <img src="https://neutrolink.in/static/images/neutrolink_assets/favicon.jpeg" className='h-1rem' alt="" />
                    </div>
                </a>
            </div>
            </div>
           
        </>
    )
}

export default Index
