import React from 'react';
import { Avatar } from 'primereact/avatar';
import { Rating } from '../..';
import Style from "./testimonialsCard.module.scss";



function Index(props) {
  return (
    <div className='border-round shadow-2 bg-white p-3'>
      <div className='flex gap-4 align-items-center'>
        <Avatar image={props.data?.files} size="large" shape="circle" className='border-circle' />
        <p className='font-bold'>{props.data?.title}</p>
      </div>
      <div className='my-4'>
        <p className={[Style.desc, 'text-700'].join(" ")}>{props?.data?.desc}</p>
      </div>
      <div>
        <Rating value={props?.data?.rating} />
      </div>
    </div>
  )
}

export default Index
