import React from 'react'

function Index() {
    return (
        <div>
            master  curse card
        </div>
    )
}

export default Index
