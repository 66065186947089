import React from 'react';
import { Rating } from 'primereact/rating';


function Index(props) {
    return (
        <Rating value={props?.value || 0} readOnly cancel={false} className={['text-yellow-500'].join(" ")} />
    )
}

export default Index
