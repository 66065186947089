import React from "react";
import pageNotFoundImg from "../../assets/images/page_not_found.svg";

function Index() {
  return (
    <div
      className="flex align-items-center justify-content-center"
      style={{ height: "80vh" }}
    >
      <div style={{ width: "35%" }}>
        <img
          src={pageNotFoundImg}
          alt="page-not-found"
          style={{ width: "100%", height: "auto" }}
        />
        <p
          className="text-center mt-2"
          style={{ fontWeight: "bold", fontSize: "4vw", color: "#312883" }}
        >
          Page Not Found
        </p>
      </div>
    </div>
  );
}

export default Index;
