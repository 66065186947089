const PriceFormat = (price) => {
    const priceInFormate = price.toLocaleString('en-IN', {
        maximumFractionDigits: 0,
        style: 'currency',
        currency: 'INR'
    });
    return priceInFormate
}


export { PriceFormat }